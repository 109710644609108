<template>
  <div ref="chart" :style="{height:height,width:width}"></div>
</template>
<script>
import {debounce} from 'lodash'
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
        type:Object,
        default:{
            xAxisData: [],
            seriesData:[]
        }
    },
    legendData:{
        type:Array,
        default:[]
    },
    units:{
      type:Array,
      default:[]
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    type:{
      type: String,
      default: '1'
    },
  },
  data() {
    return {
      color:['rgba(252,252,252,0.7)','rgba(255,78,0,0.7)', 'rgba(255,234,0,0.7)', 'rgba(90,244,177,0.7)', 'rgba(182,182,182,0.7)'],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          // console.log(val,"list==============")
          this.initCharts();
        }
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      this.chart.clear();
      let arr = _this.chartData.seriesData || [];
      //console.log('--------------->',arr)
      //if(arr.length>0){
        let seriesData = []
        arr.forEach((item,i) => {
            seriesData.push({
              name: item.name,
              type: "line",
              showSymbol: false,
              symbol: 'circle',
              smooth: true,
              lineStyle: {
                color: item.color?item.color:_this.color[i],
                width:1
              },
              itemStyle: {
                color: item.color?item.color:_this.color[i],
              },
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: item.color?item.color:_this.color[i], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0000", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              data: item.data,
            })
        });
      // 绘制图表
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor:"#09214d",
          borderWidth:1,
          borderColor:"#7ccaff",
          axisPointer:{
              type:"cross",
              lineStyle:{
                type: 'dashed',
                  // color:"rgba(102,163,215,0.3)"
              }
          },
          formatter:function(params){
            let index = params[0].dataIndex;
            let html = ""
            html+= params[0].name + '<br>'
            params.forEach((res,i)=>{
              let predata = arr[i].predata[index];
              // console.log(arr[i].predata[index],'11111')
              if(i <= arr.length -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+res.marker+res.seriesName+"率:<span style='color:"+res.color+"'>"+predata+'</span><br>'
              }
            })
            return html;
          },
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          top: 17,
          right: "4%",
          icon: "roundRect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          itemGap: 40,
          data: _this.legendData,
          textStyle: {
            fontSize: 14,
            color: "#5bd5f7",
          },
        },
        axisPointer: {
          link: {
            xAxisIndex: 'all'
          },
          label: {
            backgroundColor: 'transparent'
          }
        },
        xAxis: {
          data: _this.chartData.xAxisData,
          // splitNumber:7,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisPointer: {
           z:100
          },
          axisLabel: {
            interval: (this.type == 3 || this.type == 4) ? 0 : this.type == 2 ? 4 : 'auto',
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
            showMaxLabel: (this.type == 3 || this.type == 4) ? false : true,
            formatter: (value, index)=>{
              if(this.type == 3 || this.type == 4){
                // console.log(index)
                if(value.lastIndexOf('-01') === 7){
                  // console.log("1111",value)
                  return value.slice(0,7)
                }else{
                  return ''
                }

              }else{
                return value
              }
            }
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          },
          {
            show: false,
          },
        ],
        // '资源申请数','已提供资源数','需求项超时数'
        series: seriesData
      });
      // }else{
      //   this.chart.setOption({})
      // }
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
