<template>
  <div class="public">
    <div class="container HomePage">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="(item, i) in forCodeData" :key="i">
          <div class="box-header">
            <span>{{
              item.companyStatus == 1
                ? "登记"
                : item.companyStatus == 2
                ? "注销"
                : item.companyStatus == 3
                ? "吊销"
                : item.companyStatus == 4
                ? "歇业"
                : item.companyStatus == 5
                ? "停业"
                : ""
            }}</span>
            <span>{{ item.busNum }}</span
            >家
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{ item.yesterdayNum }}</span>
            <span
              ><i
                :class="
                  item.yesterdayNum > 0
                    ? 'el-icon-top t'
                    : item.yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ item.weekNum }}</span>
            <span
              ><i
                :class="
                  item.weekNum > 0
                    ? 'el-icon-top t'
                    : item.weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ item.monthNum }}</span>
            <span
              ><i
                :class="
                  item.monthNum > 0
                    ? 'el-icon-top t'
                    : item.monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ item.yearNum }}</span>
            <span
              ><i
                :class="
                  item.yearNum > 0
                    ? 'el-icon-top t'
                    : item.yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>主体状态区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
             v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
            ref="baidumap"
          />
          <Pmap
            v-else
              v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>主体状态新增变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <line-chart
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="['登记', '歇业', '停业', '注销', '吊销']"
            :units="['家', '家', '家', '家', '家']"
            :type="radio1"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>主体状态累计变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>

        <div class="charts">
          <line-chart
            :chartData="lineChartData2"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="['登记', '歇业', '停业', '注销', '吊销']"
            :units="['家', '家', '家', '家', '家']"
            :type="radio2"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>主体状态区域分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="登记" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="歇业" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="停业" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="注销" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="吊销" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>主体状态领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <el-table-column prop="num" label="登记" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="歇业" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="停业" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="注销" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="吊销" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/newline"; // 四码折线图
import Pmap from "@/components/charts/map.vue"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import {
  companyStatusStatistics,
  regionalCompanyStatus,
  companyStatusAdd,
  companyStatusCumulative,
  companyStatusCityStatistics,
  companyStatusIndustryStatistics,
} from "@/api/homepage";
import { cardType } from "@/api/common";

export default {
  name: "HomePage",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
    change: [Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [],
      },
      radio2: "1",
      lineChartData2: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      forCodeData: [],
      cerditStatistic: [],
      citylistname: "中国",
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        change: this.change,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        console.log("HomePage======", val);
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.companyStatusAdd();
    },
    radio2: function (val) {
      this.companyStatusCumulative();
    },

    hairpinType1: function (val) {
      this.companyStatusCityStatistics();
    },
    hairpinType2: function (val) {
      this.companyStatusIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  methods: {
    intData() {
      this.cardType();
      this.companyStatusStatistics();
      this.regionalCompanyStatus();
      this.companyStatusAdd();
      this.companyStatusCumulative();
      this.companyStatusCityStatistics();
      this.companyStatusIndustryStatistics();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    companyStatusStatistics() {
      companyStatusStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        this.forCodeData = res.data;
      });
    },
    regionalCompanyStatus() {
       this.showbmap=true;
      this.showmap=true;
      regionalCompanyStatus({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        let data = [];
        let mapdata = [];
        res.data.forEach((item) => {
          data.push({
            name: item.regionName,
            value: item.num,
            data: {
              value1: item.busNum1,
              value2: item.busNum2,
              value3: item.busNum3,
              value4: item.busNum4,
              value5: item.busNum5,
            },
          });
          mapdata.push({
            name: item.regionName,
            value: item.num,
            value1: item.busNum1,
            value2: item.busNum2,
            value3: item.busNum3,
            value4: item.busNum4,
            value5: item.busNum5,
          });
        });
        this.mapOptions = mapdata;
         this.showmap = false;
        if (this.regionType >= 3 && this.areaName) {
          this.baidumapOptions = data;
            this.showbmap = false;
        }
      });
    },

    companyStatusAdd() {
      this.lineChart1 = true;
      companyStatusAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        this.lineChart1 = false;
        if (res.data != null) {
          this.lineChartData1 = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name: "登记",
                data: res.data.num1s,
                predata: res.data.num1rate,
              },
              {
                name: "注销",
                data: res.data.num2s,
                predata: res.data.num2rate,
              },
              {
                name: "吊销",
                data: res.data.num3s,
                predata: res.data.num3rate,
              },
              {
                name: "歇业",
                data: res.data.num4s,
                predata: res.data.num4rate,
              },
              {
                name: "停业",
                data: res.data.num5s,
                predata: res.data.num5rate,
              },
            ],
          };
        } else {
          this.lineChartData1 = {
            xAxisData: [],
            seriesData: [],
          };
        }
      });
    },

    companyStatusCumulative() {
      this.lineChart2 = true;
      companyStatusCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      }).then((res) => {
        this.lineChart2 = false;
        if (res.data != "") {
          this.lineChartData2 = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name: "登记",
                data: res.data.num1s,
                predata: res.data.num1rate,
              },
              {
                name: "注销",
                data: res.data.num2s,
                predata: res.data.num2rate,
              },
              {
                name: "吊销",
                data: res.data.num3s,
                predata: res.data.num3rate,
              },
              {
                name: "歇业",
                data: res.data.num4s,
                predata: res.data.num4rate,
              },
              {
                name: "停业",
                data: res.data.num5s,
                predata: res.data.num5rate,
              },
            ],
          };
        } else {
          this.lineChartData2 = {
            xAxisData: [],
            seriesData: [],
          };
        }
      });
    },

    rowKey(row) {
      return row.regionName + row.regionId;
    },
    companyStatusCityStatistics() {
      this.loading1 = true;
      companyStatusCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },

    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    loadNextNode1(tree, treeNode, resolve) {
      console.log(this.hairpinType1, "this.hairpinType1");
      companyStatusCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        console.log(treeNode, "-------");

        resolve(res.data || []);
      });
    },

    companyStatusIndustryStatistics() {
      this.loading2 = true;
      companyStatusIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },

    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      companyStatusIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  mounted() {
    // console.log(sessionStorage.getItem("initParams"))
    if (sessionStorage.getItem("initParams") === "init") {
      console.log(123123);
      this.intData();
    }

    // this.intData();
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomePage {
  .tab-content {
    margin: 0 30px 20px 30px;
  }
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .box {
      width: 19.7%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
          #0000,
          #0000,
          #17688d,
          #17688d,
          #0000,
          #0000
        )
        30 30;

      .box-header {
        color: oldlace;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 18px;
        }
        span:nth-child(2) {
          font-size: 30px;
          margin: 0 10px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
      &:nth-child(1) {
        .box-header {
          span:nth-child(2) {
            color: #fff;
          }
        }
      }
      &:nth-child(2) {
        .box-header {
          span:nth-child(2) {
            color: #ff4646;
          }
        }
      }
      &:nth-child(3) {
        .box-header {
          span:nth-child(2) {
            color: #fecb01;
          }
        }
      }
      &:nth-child(4) {
        .box-header {
          span:nth-child(2) {
            color: #49e71f;
          }
        }
      }
      &:nth-child(5) {
        border-right: 0;
        .box-header {
          span:nth-child(2) {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
